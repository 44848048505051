import { useQuery } from '@tanstack/react-query';

import applicationsApiHandler from 'infrastructure/apiHandlers/applications';
import { popularAppsKey } from './constants';
import { IApplicationsItemsModel } from 'common/globalTypes/interfaces/apiResponseModel';

const usePopularAppsQuery = (
  initialApplicationsFromServerSide: IApplicationsItemsModel[]
) => {
  const { data: popularApps, isLoading: isLoadingPopularApps } = useQuery({
    queryKey: [popularAppsKey],
    queryFn: () => applicationsApiHandler.getPopularApps({}),
    initialData: initialApplicationsFromServerSide,
    staleTime: Infinity
  });

  return {
    popularApps,
    isLoadingPopularApps
  };
};

export default usePopularAppsQuery;
