import styled from 'styled-components';

import { sizes, colors, media } from 'styles/variables.module';

export const TemplatePopupBodyStyleContainer = styled.div`
  min-height: 500px;
  ${media.sm(`
    padding: 0 ${sizes.spm};
    height: 100%;
  `)}
`;

export const PopupHeaderStyleWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${sizes.sp5xl};
  justify-content: space-between;
  padding: ${sizes.sps} ${sizes.spm};
  border-bottom: 1px solid ${colors.gray_4};
  background: ${colors.gray_1};
`;

export const LeftBoxContainer = styled.div`
  cursor: pointer;
`;

export const RightBoxContainer = styled.div`
  cursor: pointer;
`;
