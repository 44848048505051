import { IApplicationsItemsModel } from 'common/globalTypes/interfaces/apiResponseModel';

export enum RudderStuckTrackEventTypes {
  applicationListViewed = 'Application List Viewed',
  galleryItemPreviewed = 'Gallery Item Previewed',
  setupGuideViewed = 'Setup Guide Viewed'
}

interface EventPayloadMap {
  [RudderStuckTrackEventTypes.applicationListViewed]: {
    listId: string;
    category: string;
    applications: IApplicationsItemsModel[];
  };
  [RudderStuckTrackEventTypes.galleryItemPreviewed]: {
    applicationId: number;
    imgUrl: string;
    alt: string;
    id: number;
    type: string;
  };
  [RudderStuckTrackEventTypes.setupGuideViewed]: {
    appName: string;
    appDescription: string;
    category: string;
    logoUrl: string;
    monthlyPrice: number;
    price: number;
    formattedMonthlyPrice: string;
    formattedPrice: string;
    version: string;
    documentationUrl?: string;
    trialDays: number;
    developer: string;
    rating: string;
    reviewsCount: string;
    getAppBtnText: string;
  };
}

export type PayloadForEvent<E extends RudderStuckTrackEventTypes> =
  EventPayloadMap[E];
