import styled from 'styled-components';

import { HEIGHT, media, sizes } from 'styles/variables.module';

export const HomeStyleWrapper = styled.div`
  display: grid;
  grid-template-columns: 280px auto;
  grid-template-rows: 254px auto;
  gap: ${sizes.sp2xl} ${sizes.sp2xl};
  grid-template-areas:
    'searchContainer searchContainer'
    'navContainer appsListContainer';

  ${media.md(`
    grid-auto-rows: auto;
    grid-template-columns: auto;
    grid-template-rows: 218px auto;
    grid-row-gap: ${sizes.sp2xl};
    grid-template-areas:
    'searchContainer'
    'navContainer'
    'appsListContainer'
  `)}

  ${media.sm(`
    grid-row-gap: ${sizes.spm};
  `)}
`;

export const SearchStyleContainer = styled.div`
  grid-area: searchContainer;
  ${media.sm(`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  `)}
`;

export const NavStyleContainer = styled.div`
  grid-area: navContainer;
  height: fit-content;
  position: sticky;
  top: ${HEIGHT.WEB.HEADER};

  ${media.md(`display: none;`)}
`;

export const AppsListContainer = styled.div`
  grid-area: appsListContainer;
`;

export const TitleSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
