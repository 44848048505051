import styled from 'styled-components';

import { media, sizes } from 'styles/variables.module';

export const CategoriesPopupWrapper = styled.div`
  display: none;
  padding: 0 0 0 ${sizes.spl};
  cursor: pointer;

  ${media.md('display: block')}
`;
