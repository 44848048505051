import { FC, useState } from 'react';

import Icon from 'components/atoms/Icon';
import FilterCategory from 'components/organisms/FilterCategory';
import Popup from 'components/molecules/Popup';
import useTranslations from 'hooks/common/useTranslations';
import { ICategoriesPopupProps } from './types';
import { CategoriesPopupWrapper } from './styles';

const CategoriesPopup: FC<ICategoriesPopupProps> = ({
  categories,
  selectedCategoryAlias,
  isFromMainPage = true
}) => {
  const { t } = useTranslations();
  const [isVisibleCategoryMenu, setIsVisibleCategoryMenu] = useState(false);
  const iconName = isVisibleCategoryMenu ? 'arrowUp' : 'arrowDown';

  const isVisibleCategoriesToggle = () => {
    setIsVisibleCategoryMenu(prevVisibleCategory => !prevVisibleCategory);
  };

  return (
    <CategoriesPopupWrapper onClick={isVisibleCategoriesToggle}>
      <Icon name={iconName} width={16} height={8} />
      <Popup
        title={t('common.category')}
        open={isVisibleCategoryMenu}
        onClose={() => isVisibleCategoriesToggle}
      >
        <FilterCategory
          isFromMainPage={isFromMainPage}
          menuItems={categories}
          selectedMenuItem={selectedCategoryAlias}
        />
      </Popup>
    </CategoriesPopupWrapper>
  );
};

export default CategoriesPopup;
