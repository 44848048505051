'use client';

import { FC } from 'react';
import Drawer from 'antd/lib/drawer';

import { IPopupProps } from './types';
import { bodyStyleConstant } from './constants';
import BodyText from 'components/atoms/BodyText';
import Icon from 'components/atoms/Icon';
import {
  TemplatePopupBodyStyleContainer,
  RightBoxContainer,
  PopupHeaderStyleWrapper,
  LeftBoxContainer
} from './styles';
import { sizes } from 'styles/variables.module';

const Popup: FC<IPopupProps> = ({
  title,
  open,
  onClose,
  children,
  ...props
}) => {
  return (
    <Drawer
      open={open}
      placement="bottom"
      bodyStyle={bodyStyleConstant}
      closeIcon={null}
      onClose={event => {
        onClose?.(event);
      }}
      destroyOnClose={true}
      height="95%"
      styles={{ header: { padding: '0' }, body: { padding: '0' } }}
      style={{
        borderTopRightRadius: sizes.spm,
        borderTopLeftRadius: sizes.spm
      }}
      title={
        <PopupHeaderStyleWrapper>
          <LeftBoxContainer>
            <BodyText size="base" fontWeight="bold" text={title} ellipsis />
          </LeftBoxContainer>
          <RightBoxContainer>
            <Icon
              name="closeOutline"
              width={20}
              height={20}
              onClick={event => {
                onClose?.(event);
              }}
            />
          </RightBoxContainer>
        </PopupHeaderStyleWrapper>
      }
      {...props}
    >
      <TemplatePopupBodyStyleContainer>
        {children}
      </TemplatePopupBodyStyleContainer>
    </Drawer>
  );
};

export default Popup;
